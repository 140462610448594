<template>
  <v-app-bar class="appBar" dense app elevation="0">
    <v-app-bar-nav-icon @click="toggleDrawer()">
      <v-icon>mdi-menu</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title>
      {{ calendarTitle }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      fab
      text
      small
      class="mr-0 theme--light blue--text text--darken-2"
      @click="prev"
    >
      <v-icon> mdi-chevron-left </v-icon>
    </v-btn>
    <v-btn
      fab
      text
      small
      class="mr-0 theme--light blue--text text--darken-2"
      @click="next"
    >
      <v-icon> mdi-chevron-right </v-icon>
    </v-btn>
    <h5
      class="mx-2 theme--light blue--text text--darken-2 text-capitalize"
      @click="setToday"
      style="font-size: 16px; font-weight: 400"
    >
      Today
    </h5>
  </v-app-bar>
</template>
<script>
//import { bus } from "../main";

export default {
  name: "CalendarAppBar",
  data: () => ({
    test: "Test",
  }),
  computed: {
    calendarTitle() {
      var thisYearOptions = { year: "numeric", month: "long" };
      var date =
        this.$store.state.calendar.focus !== ""
          ? new Date(this.$store.state.calendar.focus)
          : new Date();
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return date.toLocaleDateString("en-US", thisYearOptions);
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.commit("toggleDrawer", ["calendar", true]);
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.$store.commit("calendarToToday");
    },
    prev() {
      this.$store.commit("calendarArrows", [-1]);
    },
    next() {
      this.$store.commit("calendarArrows", [1]);
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
